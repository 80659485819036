import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import amplitude from 'amplitude-js';

import * as Sentry from '@sentry/react';
import Adjust from '@adjustcom/adjust-web-sdk';

import { AnalyticContext } from './analytic-context';
import userDataApiService from '../../services/web-api/user-data-api';
import { getLSValue } from '../../utils/locale-storage';
import { USER_DATA } from '../../constants/common';
import { UserLocalStorageData } from '../../types/user';
import { ipInfoService } from '../../services/ip';
import { DECIMAL_NUMBER } from '../../utils/currency';

type AnalyticProviderProps = {
  children: ReactNode;
};

type AmplitudeEvent = { name: string; props?: Record<string, any> };

type AmplitudeUserProperties = Record<string, any>;

type FBEvent = { name: string };

const AnalyticProvider = ({ children }: AnalyticProviderProps) => {
  const [isAmplitudeInit, setIsAmplitudeInit] = useState(false);

  const analyticsInit = useRef(false);
  const eventsQueue = useRef<AmplitudeEvent[]>([]);
  const userPropertiesQueue = useRef<AmplitudeUserProperties | null>(null);

  useEffect(() => {
    if (analyticsInit.current) {
      return;
    }

    const isProduction = process.env.NODE_ENV === 'production';

    if (isProduction) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
      });
    }

    analyticsInit.current = true;

    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');
    setIsAmplitudeInit(true);

    Adjust.initSdk({
      appToken: process.env.REACT_APP_ADJUST_APP_TOKEN || '',
      environment: isProduction ? 'production' : 'sandbox',
    });
  }, []);

  const sendAmplitudeEvent = useCallback(
    (event: AmplitudeEvent) => {
      const { name, props } = event;

      if (!isAmplitudeInit) {
        eventsQueue.current.push(event);
        return;
      }

      amplitude.getInstance().logEvent(name, props);
    },
    [isAmplitudeInit],
  );

  const setUserProperties = useCallback(
    (userProperties: AmplitudeUserProperties) => {
      if (!isAmplitudeInit) {
        userPropertiesQueue.current = { ...userPropertiesQueue.current, ...userProperties };
        return;
      }
      amplitude.getInstance().setUserProperties(userProperties);
    },
    [isAmplitudeInit],
  );

  const trackFBEvent = useCallback(
    async (event: { name: string }) => {
      try {
        const { name } = event;
        const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;
        const userIp = await ipInfoService.getInfo();
        const userAgent = window.navigator.userAgent;

        const payload = {
          event_name: name,
          web_app_id: 'imagin8',
          event_source_url: window.location.href || '',
          client_user_agent: userAgent || '',
          customer_id: userLSData?.userId || '',
          email: userLSData?.email || '',
          ip: userIp.ip || '',
          country: userIp.country || '',
          zip: userIp.postal || '',
          city: userIp.city || '',
          product_id: userLSData?.plan?.trialDuration
            ? userLSData?.plan?.mainPlanKey || ''
            : userLSData?.plan?.introPlanKey || '',
          revenue_usd: userLSData?.plan?.trialDuration
            ? Number(userLSData?.plan?.trialPrice) / DECIMAL_NUMBER || ''
            : Number(userLSData?.plan?.price) / DECIMAL_NUMBER || '',
          currency: 'USD',
          ads_network_data: [
            {
              channel: 'facebook',
              pixel_id: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
            },
          ],
        };

        await userDataApiService.trackFbConversion(payload);
        sendAmplitudeEvent({ name: 'fb_event', props: { event: name } });
      } catch (e: unknown) {
        console.error(e);
      }
    },
    [sendAmplitudeEvent],
  );

  const gtagEvent = useCallback((event: { name: string; props?: Record<string, any> }) => {
    const { name, props } = event;

    try {
      window.gtag('event', name, props);
    } catch (error: unknown) {
      console.error(`Failed to sent gtag event ${name}`, error);
    }
  }, []);

  const sentryCaptureException = useCallback((error: unknown) => {
    Sentry.captureException(error);
  }, []);

  useEffect(() => {
    if (!isAmplitudeInit) {
      return;
    }

    if (userPropertiesQueue.current) {
      setUserProperties(userPropertiesQueue.current);
    }

    eventsQueue.current.forEach((event) => {
      sendAmplitudeEvent(event);
    });
  }, [isAmplitudeInit, sendAmplitudeEvent, setUserProperties]);

  const analyticsValue = useMemo(
    () => ({
      sendAmplitudeEvent,
      setUserProperties,
      trackFBEvent,
      gtagEvent,
      sentryCaptureException,
      amplitudeInit: isAmplitudeInit,
    }),
    [sendAmplitudeEvent, setUserProperties, trackFBEvent, gtagEvent, sentryCaptureException, isAmplitudeInit],
  );

  return <AnalyticContext.Provider value={analyticsValue}>{children}</AnalyticContext.Provider>;
};

export {
  type AnalyticProviderProps,
  type AmplitudeEvent,
  type AmplitudeUserProperties,
  type FBEvent,
  AnalyticProvider,
};
