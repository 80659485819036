import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './input.module.scss';

type InputProps = ButtonHTMLAttributes<HTMLInputElement> & {
  className?: string;
  isError?: boolean;
};

const Input = ({ className, isError, ...props }: InputProps) => (
  <input className={clsx(styles.input, { [styles.error]: isError }, className)} {...props} />
);

export { Input };
