import React from 'react';
import clsx from 'clsx';

import styles from './loading.module.scss';

type LoadingProps = { isDark?: boolean; isCentered?: boolean; className?: string };

const Loading = ({ className, isDark }: LoadingProps) => (
  <div className={clsx(styles.ldsRing, { [styles.dark]: isDark, [styles.centered]: isDark }, className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export { Loading };
