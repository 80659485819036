import React from 'react';

import secureIconSvg from '../../assets/images/trial/secure-icon.svg';

import styles from './secure-info.module.scss';

const SecureInfo = () => (
  <div className={styles.secure}>
    <img className={styles.secureIcon} src={secureIconSvg} alt="secure icon" />
    Pay safe & secure
  </div>
);

export { SecureInfo };
