import { CURRENCY_SYMBOLS } from '../constants/currencies';

const DECIMAL_NUMBER = 100;

const getCurrency = (amount: number, currency: string = '') => {
  const resultAmount = amount ? amount / DECIMAL_NUMBER : '0';

  return `${resultAmount}${CURRENCY_SYMBOLS[currency]}`;
};

export { getCurrency, DECIMAL_NUMBER };
