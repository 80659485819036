import React, { ReactNode, MouseEvent } from 'react';

import styles from './toggle-button.module.scss';

type ToggleButtonGroupProps = {
  value: string;
  exclusive?: boolean;
  onChange: (event: MouseEvent<HTMLElement>, value: string) => void;
  children: ReactNode;
};

const ToggleButtonGroup = ({ value, onChange, children }: ToggleButtonGroupProps) => {
  const handleClick = (event: MouseEvent<HTMLElement>, val: string) => {
    onChange(event, val);
  };

  return (
    <div className={styles.toggle_button_group}>
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, {
          isSelected: child.props.value === value,
          onClick: (event: MouseEvent<HTMLElement>) => handleClick(event, child.props.value),
        }),
      )}
    </div>
  );
};

type ToggleButtonProps = {
  value: string;
  isSelected?: boolean;
  onClick?: () => void;
  children: ReactNode;
};

const ToggleButton = ({ isSelected, onClick, children }: ToggleButtonProps) => (
  <button className={`${styles.toggle_button} ${isSelected ? styles.selected : ''}`} onClick={onClick}>
    {children}
  </button>
);

export { ToggleButtonGroup, ToggleButton };
