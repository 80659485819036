import React, { ChangeEvent } from 'react';

import styles from './slider.module.scss';

type SliderProps = {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange: (event: Event, value: number | number[]) => void;
};

const MIN_VALUE = 0;
const MAX_VALUE = 100;
const STEP = 1;

const Slider = ({ min = MIN_VALUE, max = MAX_VALUE, step = STEP, value, onChange }: SliderProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(event.nativeEvent, newValue);
  };

  return (
    <div className={styles.slider_container}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className={styles.slider}
      />
    </div>
  );
};

export { Slider };
