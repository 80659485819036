const USER_DATA = 'user_data';
const MAX_STEPS = 8;
const SURVEY_STEPS_DATA = 'survey_steps_data';
const QUIZ_FINISHED = 'quiz_finished';
const PAYMENT_SUCCESS = 'payment_success';

const IS_DEV = process.env.NODE_ENV !== 'production';
const FAQ_LINK = 'https://intercom.help/wndr11/en/';

const CENTS_IN_DOLLAR = 100;

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export {
  MAX_STEPS,
  USER_DATA,
  SURVEY_STEPS_DATA,
  QUIZ_FINISHED,
  IS_DEV,
  PAYMENT_SUCCESS,
  FAQ_LINK,
  CENTS_IN_DOLLAR,
  EMAIL_REGEXP,
};
