class CookieManager {
  static readonly DEFAULT_TIMEOUT_MS = 1500;
  static readonly CHECK_INTERVAL_MS = 100;

  readCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');
    const cookie = cookies.find((c) => c.trim().startsWith(nameEQ));
    return cookie ? cookie.trim().substring(nameEQ.length) : null;
  }

  async waitForCookie(cookieName: string, timeout: number = CookieManager.DEFAULT_TIMEOUT_MS): Promise<string | null> {
    return new Promise((resolve) => {
      const startTime = Date.now();
      const checkCookie = (): void => {
        const cookieValue = this.readCookie(cookieName);
        if (cookieValue || Date.now() - startTime >= timeout) {
          clearInterval(interval);
          resolve(cookieValue || null);
        }
      };
      const interval = setInterval(checkCookie, CookieManager.CHECK_INTERVAL_MS);
    });
  }
}
const cookieManager = new CookieManager();
export { cookieManager };
