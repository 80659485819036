import React, { FC } from 'react';
import { LogoV2 } from 'src/components/logo';

import styles from './fullscreen-loader.module.scss';

const FullscreenLoader: FC = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.logo}>
        <LogoV2 />
      </div>

      <div className={styles.loader}>
        <div className={styles.loader__spinner} />

        <div className={styles.loader__text}>loading</div>
      </div>
    </div>
  </div>
);

export { FullscreenLoader };
