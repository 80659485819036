import React, { FC } from 'react';
import { ReactComponent as Imgn8Logo } from 'src/assets/icons/imgn8_logo_v3.svg';
import clsx from 'clsx';

import styles from './logo-v3.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
};

const LogoV3: FC<Props> = ({ size = 'medium' }) => (
  <div>
    <Imgn8Logo className={clsx(styles.logo, styles[size])} />
  </div>
);

export { LogoV3 };
