import React, { FC } from 'react';
import { LogoV4, Typographies, Typography } from 'src/components';
import { ReactComponent as AppleStoreIcon } from 'src/assets/icons/app_store.svg';

import styles from './brand.module.scss';

type Props = {
  onStoreClick?: (name: string) => void;
};

const Brand: FC<Props> = ({ onStoreClick }) => (
  <div className={styles.brand}>
    <div className={styles.brand__logo_icon}>
      <LogoV4 />
    </div>

    <Typography className={styles.brand__logo_title} variant={Typographies.HEADLINE_LARGE}>
      Imagin8
    </Typography>

    <Typography className={styles.brand__text} variant={Typographies.LABEL_EXTRA_LARGE}>
      Create your dream image
    </Typography>

    <a
      className={styles.brand__store}
      href={process.env.REACT_APP_APPLE_STORE}
      onClick={() => onStoreClick && onStoreClick('appstore')}
    >
      <AppleStoreIcon />
    </a>
  </div>
);

export { Brand };
