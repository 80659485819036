import React, { createContext, ReactElement, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Toast, ToastProps } from 'src/components';

type ToastStateType = ToastProps & {
  component: ReactElement;
};

type ContextStateType = {
  handleOpenToast: (data: ToastStateType) => void;
};

const ToastContext = createContext<ContextStateType>({
  handleOpenToast: () => {},
});

type ToastContextProviderProps = {
  children?: React.ReactNode;
};

const ToastContextProvider: React.FC<ToastContextProviderProps> = ({ children }) => {
  const [state, setState] = useState<ToastStateType | null>(null);

  const handleOpenToast = (data: ToastStateType) => {
    setState(data);
    toast(data.component);
    toast.clearWaitingQueue();
  };

  return (
    <ToastContext.Provider
      value={{
        handleOpenToast,
      }}
    >
      {children}
      {state && <Toast position={state.position} type={state.type} autoClose={state.autoClose} />}
    </ToastContext.Provider>
  );
};

const useToastContext = (): ContextStateType => {
  const toastContext = useContext<ContextStateType>(ToastContext);

  if (!toastContext) {
    throw Error('useToastContext hook should be wrapped by ToastContextProvider');
  }

  return toastContext;
};

export { ToastContextProvider, useToastContext };
