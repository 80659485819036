import React from 'react';
import clsx from 'clsx';
import { ReactComponent as AppStore } from 'src/assets/icons/app_store.svg';

import googlePlay from '../../assets/images/google-play.png';

import styles from './stores.module.scss';

type StoresProps = {
  onStoreClick?: (nameStore: string) => void;
  className?: string;
};

const Stores = ({ onStoreClick, className }: StoresProps) => (
  <ul className={clsx(styles.stores, className)}>
    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_APPLE_STORE}
        onClick={() => onStoreClick && onStoreClick('appstore')}
      >
        <AppStore />
      </a>
    </li>
    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_GOOGLE_PLAY}
        onClick={() => onStoreClick && onStoreClick('googleplay')}
      >
        <img src={googlePlay} alt="google play" />
      </a>
    </li>
  </ul>
);

export { Stores };
