import { RawCreatedCustomerResponse, CreatedCustomerResponse, RawCustomerResponse, CustomerResponse } from './types';

const mapCreatedCustomerResponse = (rawCreatedCustomer: RawCreatedCustomerResponse): CreatedCustomerResponse => ({
  customerId: rawCreatedCustomer.customer_id,
  status: rawCreatedCustomer.status,
});

const mapCustomerResponse = (rawCustomer: RawCustomerResponse): CustomerResponse => ({
  contactEmail: rawCustomer.contact_email,
  customerId: rawCustomer.customer_id,
  metadata: rawCustomer.metadata,
  status: rawCustomer.status,
});

export { mapCreatedCustomerResponse, mapCustomerResponse };
