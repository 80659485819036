const useQueryParams = (): {
  setQueryParams: (params: Record<string, number | string | null>) => void;
  getQueryParams: () => Record<string, number | string | null>;
  removeQueryParam: (paramKey: string) => void;
  updateQueryParams: (params: Record<string, number | string | null>, isShallow?: boolean) => void;
} => ({
  setQueryParams: (params): void => {
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value)) as Record<
      string,
      string
    >;
    const search = new URLSearchParams(filteredParams).toString();
    window.history.pushState('', '', search ? `${window.location.pathname}?${search}` : window.location.pathname);
  },
  getQueryParams: (): Record<string, number | string | null> =>
    Object.fromEntries(new URLSearchParams(location.search)),
  removeQueryParam: (paramKey: string): void => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.delete(paramKey);
    const search = currentParams.toString();
    window.history.pushState('', '', search ? `${window.location.pathname}?${search}` : window.location.pathname);
  },
  updateQueryParams: (params: Record<string, number | string | null>): void => {
    const currentParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        const values = Array.isArray(value) ? value : [value];
        currentParams.set(key, values.map(String).join(','));
      } else {
        currentParams.delete(key);
      }
    });
    const search = currentParams.toString();
    window.history.pushState('', '', search ? `${window.location.pathname}?${search}` : window.location.pathname);
  },
});
export default useQueryParams;
