import React, { FC } from 'react';
import clsx from 'clsx';
import Imgn8Logo from 'src/assets/icons/imgn8_logo_v4.webp';

import styles from './logo-v4.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
};

const LogoV4: FC<Props> = ({ size = 'medium' }) => (
  <div>
    <img src={Imgn8Logo} alt="" className={clsx(styles.logo, styles[size])} />
  </div>
);

export { LogoV4 };
