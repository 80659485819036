import React, { FC, ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getLSValue } from '../../utils/locale-storage';
import { PAYMENT_SUCCESS, USER_DATA } from '../../constants/common';
import { UserLocalStorageData } from '../../types/user';
import { ROUTES } from '../../routes/constants';

type Props = {
  children: ReactNode;
};

const PaymentSuccessMiddleware: FC<Props> = ({ children }) => {
  const currentUserData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const isPaymentSuccess = getLSValue(PAYMENT_SUCCESS, true) as boolean;
  const location = useLocation();

  const isRedirect = useMemo(
    () =>
      !!currentUserData?.email &&
      isPaymentSuccess &&
      location.pathname !== ROUTES.Success &&
      location.pathname !== ROUTES.Instruction,
    [currentUserData, isPaymentSuccess, location.pathname],
  );

  return isRedirect ? <Navigate to={ROUTES.Instruction} replace /> : <>{children}</>;
};

export { PaymentSuccessMiddleware };
