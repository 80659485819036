import React, { useState } from 'react';
import clsx from 'clsx';
import copyButtonIcon from 'src/assets/images/copy-button-icon.svg';
import copySuccessIcon from 'src/assets/images/copy-success.svg';

import styles from './copy-button.module.scss';

const DURATION = 2000;

type CopyButtonProps = {
  textToCopy: string;
  onClick?: () => void;
  className?: string;
  copyIcon?: string;
};

const CopyButton = ({ className, textToCopy, onClick, copyIcon = copyButtonIcon }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    if (onClick) {
      onClick();
    }

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, DURATION);
      })
      .catch((err) => {
        console.error('Unable to copy text: ', err);
      });
  };

  return (
    <button className={clsx(styles.copyButton, className)} onClick={handleCopyClick}>
      {textToCopy}
      <img className={styles.icon} src={isCopied ? copySuccessIcon : copyIcon} alt="copy icon" />
    </button>
  );
};

export { CopyButton };
