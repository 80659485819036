export enum Browser {
  Firefox = 'Firefox',
  Opera = 'Opera',
  SamsungBrowser = 'Samsung Browser',
  Edge = 'Edge',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Unknown = 'Unknown browser',
}

export enum OS {
  Android = 'Android',
  IosIphone = 'iPhone',
  MacOs = 'iPad | iMac | Macbook',
  Linux = 'Linux',
  Windows = 'Windows',
  Unknown = 'Unknown OS',
}

const browserList: { name: Browser; value: RegExp }[] = [
  { name: Browser.Firefox, value: /\b(firefox|fxios)\b/i },
  { name: Browser.Opera, value: /\bopr\b/i },
  { name: Browser.SamsungBrowser, value: /\bsamsungbrowser\b/i }, // Specific rule for Samsung Browser
  { name: Browser.Edge, value: /edg/i },
  { name: Browser.Chrome, value: /\b(chrome|chromium|crios)\b/i },
  { name: Browser.Safari, value: /safari/i },
];

const OSList: { name: OS; value: string }[] = [
  { name: OS.Android, value: 'Android' },
  { name: OS.IosIphone, value: 'iPhone' },
  { name: OS.MacOs, value: 'Mac' },
  { name: OS.Linux, value: 'Linux' },
  { name: OS.Windows, value: 'Win' },
];

const getUserBrowser = (userDetails: string): Browser => {
  const matchedBrowser = browserList.find((browser) => userDetails.match(browser.value));
  return matchedBrowser?.name || Browser.Unknown;
};

const getUserOS = (userDetails: string): OS => {
  const matchedOS = OSList.find((os) => userDetails.includes(os.value));
  return matchedOS?.name || OS.Unknown;
};

export const getUserAgentInfo = (): {
  browser: Browser;
  os: OS;
} => {
  const userDetails = navigator.userAgent;

  const browser = getUserBrowser(userDetails);
  const os = getUserOS(userDetails);

  return {
    browser,
    os,
  };
};
