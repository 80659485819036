/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call,
@typescript-eslint/ban-ts-comment, no-console */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestTokenEvent,
  StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js';

export type AppleGooglePayButtonsProps = {
  price: number | string | null;
  currency: string;
  onSubmit: any;
  onReady: any;
  onCancel: any;
};

export const AppleGooglePayButtons = ({ price, currency, onSubmit, onReady, onCancel }: AppleGooglePayButtonsProps) => {
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const submitPayment = useCallback(
    (result: Record<string, boolean>) => (event: PaymentRequestTokenEvent) => {
      onSubmit({ event, price, type: result?.googlePay ? 'googlePay' : 'applePay' });
    },
    [onSubmit, price],
  );

  const paymentRequestOptions = useMemo(
    () => ({
      country: 'GB',
      currency: currency?.toLowerCase(),
      total: {
        label: process.env.REACT_APP_NAME_APP,
        amount: Number(price),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    }),
    [currency, price],
  );

  useEffect(() => {
    if (stripe && price) {
      if (!paymentRequest) {
        // @ts-ignore
        const createPaymentRequest = stripe?.paymentRequest(paymentRequestOptions);

        createPaymentRequest
          .canMakePayment()
          .then((result: CanMakePaymentResult | null) => {
            if (result && (result.googlePay || result.applePay)) {
              createPaymentRequest.on('token', submitPayment(result));
              setPaymentRequest(createPaymentRequest);
            }

            onReady(result);
          })
          .catch((e) => {
            console.log('createPaymentRequest.canMakePayment', e);
            onReady(null);
          });

        createPaymentRequest.on('cancel', () => {
          onCancel();
        });
      }

      if (paymentRequest) {
        // console.log('!!!!! new price !!!!!', price);
        paymentRequest.update({
          currency: currency?.toLowerCase(),
          total: {
            label: process.env.REACT_APP_NAME_APP || '',
            amount: Number(price),
          },
        });
      }
    }
  }, [stripe, price, paymentRequestOptions, paymentRequest, onReady, submitPayment, onCancel, currency]);

  const options = useMemo<StripePaymentRequestButtonElementOptions | undefined>(() => {
    if (!paymentRequest) {
      return;
    }

    return {
      paymentRequest,
      style: {
        paymentRequestButton: {
          height: '48px',
        },
      },
    };
  }, [paymentRequest]);

  return <>{paymentRequest && <PaymentRequestButtonElement options={options} />}</>;
};
