import React, { FC } from 'react';
import { LogoV4, Typographies, Typography } from 'src/components/index';
import { ReactComponent as ApplicationIcon } from 'src/assets/icons/application.svg';

import styles from './download-app-banner.module.scss';

type Props = {
  onStoreClick?: (name: string) => void;
};

const DownloadAppBanner: FC<Props> = ({ onStoreClick }) => (
  <div className={styles.banner}>
    <div className={styles.banner__top}>
      <div className={styles.banner__logo}>
        <LogoV4 size="small" />
      </div>

      <Typography className={styles.banner__text} variant={Typographies.LABEL_LARGE}>
        Download Imagin8 app to complete registration and start the fun!
      </Typography>
    </div>

    <a
      className={styles.banner__button}
      href={process.env.REACT_APP_APPLE_STORE}
      onClick={() => onStoreClick && onStoreClick('appstore')}
    >
      <ApplicationIcon />
      <span>Download the app</span>
    </a>
  </div>
);

export { DownloadAppBanner };
