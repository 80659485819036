export const ROUTES = {
  Index: '/',
  // quiz v1
  QuizV1: '/v1',
  Character: '/character',
  Sex: '/sex',
  ManAge: '/man-age',
  ManEthnicity: '/man-ethnicity',
  ManHair: '/man-hair',
  ManBody: '/man-body',
  ManCharms: '/man-charms',
  ManClothes: '/man-clothes',
  WomanAge: '/woman-age',
  WomanEthnicity: '/woman-ethnicity',
  WomanHair: '/woman-hair',
  WomanCharms: '/woman-charms',
  WomanBody: '/woman-body',
  WomanUndress: '/woman-undress',
  WomanClothes: '/woman-clothes',
  Generate: '/generate',
  // v3
  QuizV3: '/v3',
  QuizV3Welcome: '/v3/welcome',
  QuizV3About: '/v3/about',
  QuizV3Email: '/v3/email',
  // undress
  QuizV4: '/v4',
  QuizV4Welcome: '/v4/welcome',
  QuizV4Instruction: '/v4/instruction',
  QuizV4Generation: '/v4/generation',
  QuizV4Result: '/v4/result',
  // undress with drawing
  QuizV5: '/v5',
  QuizV5Welcome: '/v5/welcome',
  QuizV5Instruction: '/v5/instruction',
  QuizV5Generation: '/v5/generation',
  QuizV5Result: '/v5/result',
  //
  Email: '/email',
  Paywall: '/paywall',
  Checkout: '/checkout',
  Success: '/success',
  Failed: '/failed',
  Instruction: '/instruction',
  DownloadApp: '/download-app',
  FAQ: 'https://intercom.help/wndr11/en/',
  ImgnFun: 'https://quiz.imagin8.fun/',
};
