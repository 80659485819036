import { lazyComponentLoader } from 'src/utils/lazy-component-loader';

import { RouterBuilder } from './RouterBuilder';
import { ROUTES } from './constants';

const QuizStartPage = lazyComponentLoader(async () => import('../features/quiz-start/quiz-start'));
const QuizV1Page = lazyComponentLoader(async () => import('../features/quiz-v1/quiz-v1'));
const SurveyPage = lazyComponentLoader(async () => import('../features/survey/survey'));
const UndressPage = lazyComponentLoader(async () => import('../features/undress/undress'));
const GeneratePage = lazyComponentLoader(async () => import('../features/generate/generate'));
const QuizV3WelcomePage = lazyComponentLoader(async () => import('../features/quiz-v3/welcome/welcome'));
const QuizV3AboutPage = lazyComponentLoader(async () => import('../features/quiz-v3/slider/slider'));
const QuizV3EmailPage = lazyComponentLoader(async () => import('../features/quiz-v3/email-screen-v2/email-screen-v2'));
const QuizV4WelcomePage = lazyComponentLoader(async () => import('../features/quiz-v4/welcome/welcome'));
const QuizV4InstructionPage = lazyComponentLoader(async () => import('../features/quiz-v4/instruction/instruction'));
const QuizV4GenerationPage = lazyComponentLoader(async () => import('../features/quiz-v4/generation/generation'));
const QuizV4ResultPage = lazyComponentLoader(async () => import('../features/quiz-v4/result/result'));
const QuizV5WelcomePage = lazyComponentLoader(async () => import('../features/quiz-v5/welcome/welcome'));
const QuizV5InstructionPage = lazyComponentLoader(async () => import('../features/quiz-v5/instruction/instruction'));
const QuizV5GenerationPage = lazyComponentLoader(async () => import('../features/quiz-v5/generation/generation'));
const QuizV5ResultPage = lazyComponentLoader(async () => import('../features/quiz-v5/result/result'));

const EmailPage = lazyComponentLoader(async () => import('../features/email/email'));
const PaywallPage = lazyComponentLoader(async () => import('../features/paywall/paywall'));
const CheckoutPage = lazyComponentLoader(async () => import('../features/checkout/checkout'));
const SuccessPage = lazyComponentLoader(async () => import('../features/success/success'));
const FailedPage = lazyComponentLoader(async () => import('../features/failed/failed'));
const InstructionPage = lazyComponentLoader(async () => import('../features/instruction/instruction'));
const DownloadAppPage = lazyComponentLoader(async () => import('../features/download-app/download-app'));

const QuizStartRoute = {
  path: ROUTES.Index,
  Component: QuizStartPage,
};

const QuizV1Route = {
  path: ROUTES.QuizV1,
  Component: QuizV1Page,
};

// TODO! Yura. Discuss a possibility to remove unused pages to decrease a js bundle
const SurveyRoutes = [
  ROUTES.Character,
  ROUTES.Sex,
  ROUTES.ManAge,
  ROUTES.ManEthnicity,
  ROUTES.ManHair,
  ROUTES.ManBody,
  ROUTES.ManCharms,
  ROUTES.ManClothes,
  ROUTES.WomanAge,
  ROUTES.WomanEthnicity,
  ROUTES.WomanHair,
  ROUTES.WomanCharms,
  ROUTES.WomanBody,
  ROUTES.WomanClothes,
].map((path) => ({ path, Component: SurveyPage }));

const UndressRoute = {
  path: ROUTES.WomanUndress,
  Component: UndressPage,
};

const GenerateRoute = {
  path: ROUTES.Generate,
  Component: GeneratePage,
};

const QuizV3WelcomeRoute = {
  path: ROUTES.QuizV3Welcome,
  Component: QuizV3WelcomePage,
};

const QuizV3AboutRoute = {
  path: ROUTES.QuizV3About,
  Component: QuizV3AboutPage,
};

const QuizV3EmailRoute = {
  path: ROUTES.QuizV3Email,
  Component: QuizV3EmailPage,
};

const QuizV4WelcomeRoute = {
  path: ROUTES.QuizV4Welcome,
  Component: QuizV4WelcomePage,
};

const QuizV4InstructionRoute = {
  path: ROUTES.QuizV4Instruction,
  Component: QuizV4InstructionPage,
};

const QuizV4GenerationRoute = {
  path: ROUTES.QuizV4Generation,
  Component: QuizV4GenerationPage,
};

const QuizV4ResultRoute = {
  path: ROUTES.QuizV4Result,
  Component: QuizV4ResultPage,
};

const QuizV5WelcomeRoute = {
  path: ROUTES.QuizV5Welcome,
  Component: QuizV5WelcomePage,
};

const QuizV5InstructionRoute = {
  path: ROUTES.QuizV5Instruction,
  Component: QuizV5InstructionPage,
};

const QuizV5GenerationRoute = {
  path: ROUTES.QuizV5Generation,
  Component: QuizV5GenerationPage,
};

const QuizV5ResultRoute = {
  path: ROUTES.QuizV5Result,
  Component: QuizV5ResultPage,
};

const EmailRoute = {
  path: ROUTES.Email,
  Component: EmailPage,
};

const PaywallRoute = {
  path: ROUTES.Paywall,
  Component: PaywallPage,
};

const CheckoutRoute = {
  path: ROUTES.Checkout,
  Component: CheckoutPage,
};

const SuccessRoute = {
  path: ROUTES.Success,
  Component: SuccessPage,
};

const FailedRoute = {
  path: ROUTES.Failed,
  Component: FailedPage,
};

const InstructionRoute = {
  path: ROUTES.Instruction,
  Component: InstructionPage,
};

const DownloadAppRoute = {
  path: ROUTES.DownloadApp,
  Component: DownloadAppPage,
};

RouterBuilder.addPublicRoutes([
  QuizStartRoute,
  QuizV1Route,
  GenerateRoute,
  ...SurveyRoutes,
  UndressRoute,
  QuizV3WelcomeRoute,
  QuizV3AboutRoute,
  QuizV3EmailRoute,
  QuizV4WelcomeRoute,
  QuizV4InstructionRoute,
  QuizV4GenerationRoute,
  QuizV4ResultRoute,
  QuizV5WelcomeRoute,
  QuizV5InstructionRoute,
  QuizV5GenerationRoute,
  QuizV5ResultRoute,
]);

RouterBuilder.addProtectedRoutes([
  EmailRoute,
  PaywallRoute,
  CheckoutRoute,
  SuccessRoute,
  FailedRoute,
  InstructionRoute,
  DownloadAppRoute,
]);
