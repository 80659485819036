import { useContext } from 'react';

import { AnalyticContext } from './analytic-context';

const useAnalytic = () => {
  const context = useContext(AnalyticContext);

  if (!context) {
    throw new Error('useAnalytic must be used within an AnalyticProvider');
  }

  return context;
};

export { useAnalytic };
