import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './modal.module.scss';

type ModalProps = {
  isBlurOverlay?: boolean;
  children: ReactNode;
  position?: 'center' | 'bottom';
  modalClassName?: string;
  overlayClassName?: string;
};

const Modal = ({ isBlurOverlay, children, modalClassName, overlayClassName, position = 'center' }: ModalProps) => (
  <div className={clsx(styles.overlay, styles[position], { [styles.blur]: isBlurOverlay }, overlayClassName)}>
    <div className={clsx(styles.modal, modalClassName)}>{children}</div>
  </div>
);

export { Modal, type ModalProps };
