const USD_CURRENCY = 'USD';

const currencySymbols: Record<string, string> = {
  [USD_CURRENCY]: '$',
};

const CURRENCY_SYMBOLS = new Proxy(currencySymbols, {
  get(target, prop: string): string {
    return prop in target ? target[prop] : '';
  },
});

export { USD_CURRENCY, CURRENCY_SYMBOLS };
