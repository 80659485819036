import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { useGrowthBook } from '@growthbook/growthbook-react';
import Adjust from '@adjustcom/adjust-web-sdk';

import { useAnalytic } from '../contexts';
import { IS_DEV, USER_DATA } from '../constants/common';
import { useGtagClientId } from '../hooks/use-gtag-client-id';
import { getLSValue } from '../utils/locale-storage';
import { UserLocalStorageData } from '../types/user';
import { useTimeout } from '../hooks/use-timer';

type Props = {
  children: ReactNode;
};

const TIMEOUT_DELAY = 3000;

const InitialAnalytics: FC<Props> = ({ children }) => {
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const [isTimeoutExpired, setIsTimeoutExpired] = useState(false);

  useTimeout(() => setIsTimeoutExpired(true), TIMEOUT_DELAY);

  const growthbook = useGrowthBook();
  const { setUserProperties, sendAmplitudeEvent, amplitudeInit } = useAnalytic();

  const { gtagClientId } = useGtagClientId();

  const sendAnalytics = useCallback(() => {
    const features = growthbook.getFeatures();
    const featureKeys = Object.keys(features);

    const userProperties: Record<string, unknown> = {};
    const featuresData: Record<string, unknown> = {};

    featureKeys.forEach((key) => {
      const feature = growthbook.evalFeature(key);

      userProperties[key] = feature.value;
      featuresData[key] = feature;
    });

    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('[all-features]', featuresData);
    }

    setUserProperties(userProperties);

    sendAmplitudeEvent({
      name: 'ab_start',
    });
  }, [growthbook, sendAmplitudeEvent, setUserProperties]);

  useEffect(() => {
    if (gtagClientId && growthbook?.ready) {
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id: gtagClientId,
      });
    }
  }, [growthbook, gtagClientId]);

  useEffect(() => {
    (async () => {
      if (!growthbook?.ready || !amplitudeInit || !userLSData?.userId) {
        return;
      }

      try {
        const webUuid = Adjust.getWebUUID() || '';

        setUserProperties({ customer_id: userLSData.userId, web_uuid: webUuid });

        sendAnalytics();
      } catch (error: unknown) {
        console.error('Failed to send initial analytics', error);
      }
    })();
  }, [amplitudeInit, growthbook, sendAnalytics, setUserProperties, userLSData?.userId]);

  if (!isTimeoutExpired && !growthbook.ready) {
    return null;
  }

  return <>{children}</>;
};

export { InitialAnalytics };
