import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './typography.module.scss';

enum Typographies {
  HEADLINE_LARGE = 'headline_large',
  HEADLINE_LARGE_ITAL = 'headline_large_ital',
  HEADLINE_MEDIUM = 'headline_medium',
  HEADLINE_MEDIUM_ITAL = 'headline_medium_ital',
  HEADLINE_SMALL = 'headline_small',
  TITLE_LARGE = 'title_large',
  TITLE_MEDIUM = 'title_medium',
  TITLE_SMALL = 'title_small',
  DISPLAY_LARGE = 'display_large',
  DISPLAY_MEDIUM = 'display_medium',
  DISPLAY_SMALL = 'display_small',
  LABEL_EXTRA_LARGE = 'label_extra_large',
  LABEL_LARGE = 'label_large',
  LABEL_MEDIUM = 'label_medium',
  LABEL_SMALL = 'label_small',
  BODY_LARGE = 'body_large',
  BODY_MEDIUM = 'body_medium',
  BODY_SMALL = 'body_small',
}

type Props = {
  variant: Typographies;
  tag?: keyof React.JSX.IntrinsicElements;
  children: ReactNode;
  className?: string;
};

const Typography: FC<Props> = ({ variant, tag: Tag = 'div', className, children }) => (
  <Tag className={clsx(styles.typography, styles[variant], className)}>{children}</Tag>
);

export { Typography, Typographies };
