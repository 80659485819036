import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../routes/constants';

const ThemeColor = () => {
  const location = useLocation();

  useEffect(() => {
    let themeColor = '#fff';

    switch (location.pathname) {
      case ROUTES.Index:
      case ROUTES.Generate:
        themeColor = '#000';
        break;
      default:
        themeColor = '#fff';
        break;
    }

    const existingMetaTag = document.querySelector('meta[name="theme-color"]');

    if (existingMetaTag) {
      existingMetaTag.setAttribute('content', themeColor);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = 'theme-color';
      newMetaTag.content = themeColor;
      document.head.appendChild(newMetaTag);
    }
  }, [location.pathname]);

  return null;
};

export { ThemeColor };
