import { FC, useState } from 'react';
import clsx from 'clsx';

import styles from './image.module.scss';

type Props = {
  src: string;
  preview: string;
  srcImgClassName?: string;
  previewImgClassName?: string;
};

const Image: FC<Props> = ({ src, preview, srcImgClassName, previewImgClassName }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.image__wrapper}>
      <img
        className={clsx(styles.image__main, srcImgClassName && srcImgClassName)}
        src={src}
        onLoad={(): void => setIsLoaded(true)}
        alt=""
      />

      <img
        className={clsx(styles.image__preview, previewImgClassName && previewImgClassName, {
          [styles.is_hidden]: isLoaded,
        })}
        src={preview}
        alt=""
      />
    </div>
  );
};

export { Image };
