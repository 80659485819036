import React, { createElement, lazy, LazyExoticComponent } from 'react';

type DynamicImportType<T> = () => Promise<{ default: React.ComponentType<T> }>;
type LazyComponentType<T> = LazyExoticComponent<React.ComponentType<T>>;

const lazyComponentLoader = <T>(component: DynamicImportType<T>): LazyComponentType<T> =>
  lazy(async () => {
    try {
      const module = await component();

      return { default: module.default };
    } catch (error: unknown) {
      console.error(error);

      return {
        default: () => createElement('div', null),
      };
    }
  });

export { lazyComponentLoader };
