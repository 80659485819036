import React, { FC } from 'react';
import { FAQ_LINK } from 'src/constants/common';
import { Typographies, Typography } from 'src/components';

import styles from './footer.module.scss';

const Footer: FC = () => (
  <div className={styles.footer}>
    <div className={styles.footer__links}>
      <a href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK} className={styles.footer__terms_link}>
        Terms of Use
      </a>

      <a href={process.env.REACT_APP_PRIVACY_POLICY} className={styles.footer__terms_link}>
        Privacy Policy
      </a>

      <a href={FAQ_LINK} className={styles.footer__terms_link}>
        FAQ
      </a>
    </div>

    <Typography variant={Typographies.LABEL_LARGE} className={styles.footer__email}>
      Contact us at{' '}
      <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} className={styles.link}>
        {process.env.REACT_APP_COMPANY_EMAIL}
      </a>
    </Typography>

    <Typography variant={Typographies.LABEL_LARGE} className={styles.footer__copyright}>
      Copyright © 2024 WNDR11 LTD. All rights reserved
    </Typography>
  </div>
);

export { Footer };
