import { useState } from 'react';

export const useGtagClientId = () => {
  const [gtagClientId, setGtagClientId] = useState('');

  if (window.gtag && !gtagClientId) {
    window.gtag('get', process.env.REACT_APP_GTM_ID || '', 'client_id', (clientId: string) => {
      localStorage.setItem('gtagClientId', clientId);
      setGtagClientId(clientId);
    });
  }

  return {
    gtagClientId,
  };
};
