import HttpService from 'src/services/http';

class IpInfoService extends HttpService {
  async getInfo(): Promise<Record<string, string>> {
    return this.get('https://ipinfo.io/json');
  }
}

const ipInfoService = new IpInfoService();

export { ipInfoService };
