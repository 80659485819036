import { RouteItemType } from './types';
import { ROUTES } from './constants';

class Router {
  private authRoutes: RouteItemType[] = [];
  private protectedRoutes: RouteItemType[] = [];

  constructor() {
    this.authRoutes = [];
    this.protectedRoutes = [];
  }

  getPublicRoutes(): RouteItemType[] {
    return this.authRoutes;
  }

  getProtectedRoutes(): RouteItemType[] {
    return this.protectedRoutes;
  }

  addProtectedRoutes(route: RouteItemType[]): void {
    this.protectedRoutes = [...this.protectedRoutes, ...route];
  }

  addPublicRoutes(route: RouteItemType[]): void {
    this.authRoutes = [...this.authRoutes, ...route];
  }

  getDefaultPublicRoutePath(): string {
    return ROUTES.QuizV3Welcome;
  }

  getDefaultProtectedRoutePath(): string {
    return ROUTES.Email;
  }
}

export const RouterBuilder = new Router();
