/* eslint-disable @typescript-eslint/naming-convention,deprecation/deprecation,react-hooks/exhaustive-deps */
import React, { FC, ReactNode, useLayoutEffect } from 'react';
import amplitude from 'amplitude-js';

import { getUserAgentInfo, OS } from '../../utils/userAgentInfo';
import { useAnalytic } from '../../contexts';
import { ROUTES } from '../../routes/constants';

type Props = {
  children: ReactNode;
};

const BotDetectionMiddleware: FC<Props> = ({ children }) => {
  const { sendAmplitudeEvent, setUserProperties } = useAnalytic();

  useLayoutEffect(() => {
    const userAgentInfo = getUserAgentInfo();
    const regex = new RegExp('bot|google|baidu|bing|msn|teoma|slurp|yandex', 'i');
    const isBot = regex.test(navigator.userAgent);

    if (userAgentInfo.os === OS.Windows || isBot) {
      setUserProperties({
        device_resolution_width: window.innerWidth,
        device_resolution_height: window.innerHeight,
        'navigator.user_agent': navigator.userAgent,
        'navigator.vendor': navigator.vendor,
        'navigator.app_name': navigator.appName,
        'navigator.platform': navigator.platform,
      });

      sendAmplitudeEvent({
        name: 'redirect',
        props: {
          reason: 'ad_bot',
        },
      });

      const deviceId = amplitude.getInstance().getDeviceId();
      const sessionId = amplitude.getInstance().getSessionId();

      const redirectUrl = process.env.REACT_APP_BOT_REDIRECT_URL || ROUTES.ImgnFun;

      window.location.replace(`${redirectUrl}?ampDeviceId=${deviceId}&ampSessionId=${sessionId}`);
    }
  }, []);

  return <>{children}</>;
};

export { BotDetectionMiddleware };
