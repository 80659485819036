// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setLSValue = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
  return true;
};

const getLSValue = (key: string, parse = false) => {
  try {
    const value = window.localStorage.getItem(key);

    if (value && parse) {
      return JSON.parse(value);
    }

    return value;
  } catch (error: unknown) {
    console.error(error);
  }
};

const removeLSValue = (key: string) => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (error: unknown) {
    console.error(error);
  }
};

export { setLSValue, getLSValue, removeLSValue };
