import React, { FC } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close_icon.svg';
import clsx from 'clsx';

import styles from './toast.module.scss';

type ToastProps = {
  position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
  autoClose?: number | false | undefined;
  type?: 'info' | 'success' | 'warning' | 'error';
};

const Toast: FC<ToastProps> = ({ position = 'top-center', autoClose = false, type = 'error' }) => (
  <div>
    <ToastContainer
      limit={1}
      className={clsx(styles.container, styles[position])}
      toastClassName={clsx(styles.toast, styles[type])}
      progressClassName={clsx(styles.progress, styles[type])}
      autoClose={autoClose}
      position={position}
      transition={Slide}
      closeButton={({ closeToast }) => (
        <button className={styles.close__button} type="button" onClick={closeToast}>
          <CloseIcon className={styles.close__icon} />
        </button>
      )}
    />
  </div>
);

export { Toast, type ToastProps };
