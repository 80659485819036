import { createContext } from 'react';

type AnalyticContextProps = {
  sendAmplitudeEvent: (event: { name: string; props?: Record<string, any> }) => void;
  setUserProperties: (userProperties: Record<string, any>) => void;
  trackFBEvent: (event: { name: string; props?: Record<string, any>; custom?: boolean }) => void;
  gtagEvent: (event: { name: string; props?: Record<string, any> }) => void;
  sentryCaptureException: (error: unknown) => void;
  amplitudeInit: boolean;
};

const AnalyticContext = createContext<AnalyticContextProps>({
  sendAmplitudeEvent: () => undefined,
  setUserProperties: () => undefined,
  trackFBEvent: () => undefined,
  gtagEvent: () => undefined,
  sentryCaptureException: () => undefined,
  amplitudeInit: false,
});

export { type AnalyticContextProps, AnalyticContext };
