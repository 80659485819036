import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { RotatingLines } from 'react-loader-spinner';

import styles from './button.module.scss';

const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACCENT: 'accent',
};

type ButtonVariant = (typeof BUTTON_VARIANTS)[keyof typeof BUTTON_VARIANTS];

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  children: ReactNode;
  variant?: ButtonVariant;
  icon?: ReactNode;
  isSmall?: boolean;
  isLoading?: boolean;
  loaderProps?: {
    width?: string;
    visible?: boolean;
    strokeWidth?: string;
    strokeColor?: string;
    animationDuration?: string;
    ariaLabel?: string;
  };
};

const Button = ({
  icon,
  className,
  children,
  isLoading,
  isSmall,
  variant = BUTTON_VARIANTS.PRIMARY,
  loaderProps = {},
  ...props
}: ButtonProps) => (
  <button className={clsx(styles.button, styles[variant], { [styles.small]: isSmall }, className)} {...props}>
    <>
      {isLoading ? (
        <RotatingLines
          visible
          width="30"
          strokeColor="grey"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          {...loaderProps}
        />
      ) : (
        <>
          {children}
          {Boolean(icon) && <div className={styles.icon}>{icon}</div>}
        </>
      )}
    </>
  </button>
);

export { Button, type ButtonProps, BUTTON_VARIANTS, type ButtonVariant };
