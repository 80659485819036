import React from 'react';
import clsx from 'clsx';

import styles from './loading-bar.module.scss';

type LoadingBarProps = {
  className?: string;
  progress: { percents: number; label: string };
  text?: string;
};

const LoadingBar = ({ className, progress, text }: LoadingBarProps) => (
  <div className={clsx(styles.root, className)}>
    <div className={styles.bar} style={{ width: `${progress.percents}%` }} />
    <p className={styles.text}>{text || progress.label}</p>
  </div>
);

export { LoadingBar };
