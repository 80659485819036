import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PublicRoute } from './PublicRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { RouterBuilder } from './RouterBuilder';
import './routes';

const publicRoutes = RouterBuilder.getPublicRoutes();
const protectedRoutes = RouterBuilder.getProtectedRoutes();

export const RouterProvider: FC = () => (
  <Suspense>
    <Routes>
      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<PublicRoute Component={Component} />} />
      ))}

      {protectedRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<ProtectedRoute Component={Component} />} />
      ))}

      <Route path="*" element={<Navigate to={RouterBuilder.getDefaultPublicRoutePath()} />} />
    </Routes>
  </Suspense>
);
