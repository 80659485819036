import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { ScrollToTop, ThemeColor } from '../components';
import { AnalyticProvider, ModalContextProvider, ToastContextProvider } from '../contexts';
import { InitialAnalytics } from './initial-analytics';
import { IS_DEV, USER_DATA } from '../constants/common';
import { PaymentSuccessMiddleware } from '../middlewares/payment-success/payment-success';
import { BotDetectionMiddleware } from '../middlewares/windows-devices/windows-devices';
import { RouterProvider } from '../routes/RouterProvider';
import { cookieManager } from '../services/cookies';
import { webApiService } from '../services/web-api';
import userDataApiService from '../services/web-api/user-data-api';
import { FullscreenLoader } from '../components/fullscreen-loader';
import { getLSValue, setLSValue } from '../utils/locale-storage';
import useQueryParams from '../hooks/use-query-params';
import { ipInfoService } from '../services/ip';
import { UserLocalStorageData } from '../types/user';

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
  subscribeToChanges: true,
  onFeatureUsage: (featureKey, result?: { value?: string }) => {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('[Feature]', { featureKey, result: result?.value });
    }
  },
});

growthbook.init();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const { getQueryParams } = useQueryParams();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (userLSData?.userId) {
          return;
        }

        const queryParams = getQueryParams();

        if (queryParams.customer_id) {
          setLSValue(USER_DATA, { userId: queryParams.customer_id });
          return;
        }

        const userResponse = await webApiService.createCustomer({ install: 'web' });

        const userIp = await ipInfoService.getInfo();
        const userAgent = window.navigator.userAgent;

        const [fbpid, fbclid] = await Promise.all([
          cookieManager.waitForCookie('_fbp'),
          cookieManager.waitForCookie('_fbc'),
        ]);

        const collectedData = {
          event_name: 'page_load',
          web_app_id: 'imagin8',
          event_source_url: window.location.href,
          client_user_agent: userAgent,
          customer_id: userResponse.customerId,
          ip: userIp.ip || '',
          country: userIp.country || '',
          zip: userIp.postal || '',
          city: userIp.city || '',
          ads_network_data: [
            {
              fbc: fbclid || queryParams.fbclid || '',
              fbp: fbpid || queryParams.fbp || '',
              channel: 'facebook',
              // eslint-disable-next-line max-len
              pixel_id: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
            },
          ],
        };

        setLSValue(USER_DATA, { userId: userResponse.customerId });
        await userDataApiService.loadCustomerTracking(collectedData);
      } catch (e: unknown) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLSData]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userLSData?.userId) {
        return;
      }

      await growthbook.setAttributes({
        user_id: userLSData.userId,
        device_id: window.navigator.userAgent,
        device_language: window.navigator.language?.toLowerCase() || 'en',
        browser: window.navigator.userAgent,
        url: window.location.host,
      });
    })();
  }, [userLSData]);

  return (
    <>
      {isLoading ? (
        <FullscreenLoader />
      ) : (
        <GrowthBookProvider growthbook={growthbook}>
          <AnalyticProvider>
            <BrowserRouter>
              <InitialAnalytics>
                <BotDetectionMiddleware>
                  <PaymentSuccessMiddleware>
                    <ModalContextProvider>
                      <ToastContextProvider>
                        <ScrollToTop />
                        <ThemeColor />
                        <RouterProvider />
                      </ToastContextProvider>
                    </ModalContextProvider>
                  </PaymentSuccessMiddleware>
                </BotDetectionMiddleware>
              </InitialAnalytics>
            </BrowserRouter>
          </AnalyticProvider>
        </GrowthBookProvider>
      )}
    </>
  );
}

export default App;
