import React, { FC } from 'react';
import clsx from 'clsx';

import Imgn8Logo from '../../../assets/icons/imgn8_logo_v2.svg';

import styles from './logo-v2.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
};

const LogoV2: FC<Props> = ({ size = 'medium' }) => (
  <div>
    <img src={Imgn8Logo} alt="" className={clsx(styles.logo, styles[size])} />
  </div>
);

export { LogoV2 };
