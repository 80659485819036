import React from 'react';
import clsx from 'clsx';

import styles from './title.module.scss';

type TitleProps = {
  title: string;
  secondPartTitle?: string;
  className?: string;
  isSmall?: boolean;
  tag?: string;
};

const Title = ({ tag = 'h1', title, secondPartTitle, className, isSmall = false }: TitleProps) => {
  const Tag = tag as keyof React.JSX.IntrinsicElements;

  return (
    <Tag className={clsx(styles.title, { [styles.isSmall]: isSmall }, className)}>
      {title} {isSmall ? <br /> : null} <span className={styles.secondPartTitle}>{secondPartTitle}</span>
    </Tag>
  );
};

export { Title };
